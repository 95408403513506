import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { clearError } from 'src/redux/error';
import { useDispatch } from 'react-redux';
import {KNOWN_ERRORS} from 'src/Core/request'

export default function ModalError() {
  const { data, ...rest } = useSelector((state) => state.error);
  const test = useSelector((state) => state.error);
  const dispatch = useDispatch();

  const message =
    KNOWN_ERRORS[data?.message?.message ?? data?.message] ??
    data?.message?.message ??
    data?.message;

  // console.log('data', data, rest);

  return (
    <Dialog
      open={!!data}
      scroll={'paper'}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Ошибка</DialogTitle>
      <DialogContent>
        <DialogContentText
          sx={{ minWidth: '40vh', minHeight: '20vh' }}
          id="alert-dialog-description"
        >
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => dispatch(clearError())}>Закрыть</Button>
      </DialogActions>
    </Dialog>
  );
}