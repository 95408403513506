import React, { useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import useApi from 'src/Api/useApi';
import CircularProgress from '@mui/material/CircularProgress';

export const oneMegabyteInBytes = 1048576;

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default function FileUpload({
  url,
  onUploadFinish,
  onUploadStart,
  multiple = false,
  additionalData,
  maxFileSize,
  onError,
  allowedExtentions
}) {
  const cancelUpload = useRef(null);
  const fileInput = useRef(null);
  const { api, inProgress } = useApi();

  const onFileUpload = async (files) => {
    if (!files) {
      return;
    }

    if (maxFileSize) {
      var fSize = files[0].size
      var megSize = fSize/ oneMegabyteInBytes
      var fixed = megSize.toFixed(4)
      var filesize = (files[0].size / oneMegabyteInBytes).toFixed(4);
      if (filesize > maxFileSize) {
        onError?.('Файл слишком большого размера.');
        return;
      }
    }

    const formData = new FormData();

    formData.append('file', files[0]);
    additionalData.forEach((element) => {
      formData.append(element.code, element.value);
    });

    onUploadStart?.(files);
    const result = await api(url, formData, {
      setCancelCallback: (callback) => (cancelUpload.current = callback),
    });
    onUploadFinish?.(result);
  };

  useEffect(() => {
    return () => cancelUpload?.current?.abort();
  }, []);

  return (
    <>
      <Button
        component="label"
        role={undefined}
        variant="contained"
        tabIndex={-1}
        startIcon={<CloudUploadIcon />}
        disabled={inProgress}
      >
        Загрузить файл
        <VisuallyHiddenInput
          ref={fileInput}
          type="file"
          accept={allowedExtentions?.join(',') ?? ''}
          onChange={(event) => {
            onFileUpload(event.target.files);
            event.target.value = '';
          }}
          multiple={multiple}
          onClick={() => {
            fileInput.current.value = null;
          }}
        />
        {inProgress && (
          <CircularProgress
            size={24}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
          />
        )}
      </Button>
    </>
  );
}
